import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { injectScript, removeScript } from '../utils/scriptInjectorManager';
import SinglePdfLink from './SinglePdfLink';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

import './styles/Login.css';

const Login = () => {

    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const savedLoginStatus = localStorage.getItem('isLoggedIn');
        return savedLoginStatus === 'true'; // Convert string to boolean
    });

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            await axios.get('https://labellaitalia.family/api/LISTA_CLIENTI?select=ID_CLIENTE,NOME,CODICE_CLIENTE,EMAIL,CELLULARE&USERNAME=eq.' + username + '&PASSWORD=eq.' + password, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYm90X2FkbWluIn0.c5Ww2JyWjlEYMy2O1EsCMGx6DYM3usCVfIbYc0ZJrK8",
                },
            }).then(result => {
                if (result.status === 200 && result.data.length === 1) {
                    var response = result.data[0]
                    localStorage.setItem('login-token-username', response);
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('username', username)
                    sessionStorage.setItem('username', username)
                    localStorage.setItem('nome', response.NOME)
                    sessionStorage.setItem('nome', response.NOME)
                    setIsLoggedIn(true);
                } else {
                    setMessage("Ungültige Zugangsdaten");
                }
            });
        } catch (error) {
            setMessage('Ungültige Anmeldedaten');
            setIsLoggedIn(false);
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('nome')
            localStorage.removeItem('nome')
            localStorage.removeItem('isLoggedIn')
            setTimeout(() => {
                window.location.reload(); // Reload the page
            }, 1500);
        }
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        setPassword('');
        localStorage.removeItem('login-token-username');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('username')
        sessionStorage.removeItem('nome')
        localStorage.removeItem('nome')
        removeScript('inject_config');
        removeScript('inject_script');
        window.location.reload();
    };

    const SESSION_TIMEOUT = 30 * 60 * 1000; // TIMEOUT 30 Minutes

    const sessionTimeout = setTimeout(() => {
        // Action when session expires (e.g., logout or show a warning)
        sessionStorage.clear();
    }, SESSION_TIMEOUT);

    useEffect(() => {
        if (isLoggedIn) {
            // Inject scripts when the user logs in
            injectScript('https://cdn.botpress.cloud/webchat/v2.1/inject.js', 'inject_script');
            injectScript('https://mediafiles.botpress.cloud/a8438638-cd49-477d-a13b-235da0a1b25a/webchat/v2.1/config.js', 'inject_config');
            injectScript('/webchatInit.js', 'webchatInit');
        } else {
            clearTimeout(sessionTimeout);
            // Remove scripts when the user logs out
            removeScript('inject_script');
            removeScript('inject_config');
            removeScript('webchatInit');
        }

        // Cleanup function to remove scripts when the component unmounts
        return () => {
            clearTimeout(sessionTimeout);
            removeScript('inject_script');
            removeScript('inject_config');
            removeScript('webchatInit');
        };
    // eslint-disable-next-line
    }, [isLoggedIn]);

    return (
        <div className="login-container">
            {isLoggedIn ? (
                <div>
                    <div>
                        <h1>Wilkommen, {localStorage.getItem('nome')}!</h1>
                        <SinglePdfLink isLoggedIn={isLoggedIn} />
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="empty-row" style={{ height: '15px' }}></div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <Button className="d-block mx-auto" variant="danger" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faPowerOff} size="3x" aria-hidden="true" />
                        </Button>
                    </div>
                </div>

            ) : (
                <Form onSubmit={handleLogin} className="login-form">
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="formBasicUsername">
                                <Form.Label>Benutzername</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Benutzername eintragen"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Kennwort</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Kennwort eintragen"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-center">
                            <Button variant="primary" type="submit">
                                Anmelden
                            </Button>
                            <br></br>
                            <p>{message}</p>
                        </Col>
                    </Row>
                </Form>
            )}
        </div>
    );
};

export default Login;
