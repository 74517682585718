// src/App.js
import React, { useState } from 'react';
import Header from './components/Header';
import Login from './components/Login';

const App = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Simulate a login action
    const login = () => {
        // Here, you would have your real authentication logic (e.g., API call)
        setIsAuthenticated(true); // After successful login
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    return (

        <div>
            <Header />
            {!isAuthenticated ? (
                // Protected content for logged-in users
              <div>
                    <Login />                
              </div>
            ) : (
                // Login form or button for non-authenticated users
                <div>
                    <Login />                
                </div>
            )}
        </div>

    );
};

export default App;
