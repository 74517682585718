import React from 'react';
import './styles/SinglePdfLink.css';

const SinglePdfLink = ({ isLoggedIn }) => {
    return (
        <div className="container mt-5">
            {isLoggedIn ? (
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-wrap justify-content-between">
                            <a href="https://www.labellaitalia.family/files/Unsere_Produkte.pdf"
                                target="_new" rel="noopener noreferrer"
                                className="btn btn-outline-danger btn-custom btn-custom-width text-center d-flex align-items-center justify-content-center">
                                <h1>Feinkost</h1>
                            </a>
                            <a href="https://www.labellaitalia.family/files/Weinkeller.pdf"
                                target="_new" rel="noopener noreferrer"
                                className="btn btn-outline-danger btn-custom btn-custom-width text-center d-flex align-items-center justify-content-center">
                                <h1>Weinkeller</h1>
                            </a>
                        </div>
                    </div>
                </div>

            ) : (
                <p>Bitte erst einloggen</p>
            )}
        </div>
    );
};

export default SinglePdfLink;
