import React from 'react';
import image4 from "../assets/Immagine4.jpeg";
import image5 from "../assets/Immagine5.jpeg";
import image6 from "../assets/Immagine6.jpeg";
import './styles/Header.css'; // For additional responsive styles

const Header = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                <br></br><br></br><br></br><br></br>
                </div>
            </div>
            <div className="row g-0"> {/* Added class for targeting */}
                <div className="col-4 p-0"> {/* Remove padding for the columns */}
                    <img src={image4} alt="4" className="img-fluid" />
                </div>
                <div className="col-4 p-0"> {/* Remove padding for the columns */}
                    <img src={image5} alt="5" className="img-fluid" />
                </div>
                <div className="col-4 p-0"> {/* Remove padding for the columns */}
                    <img src={image6} alt="6" className="img-fluid" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <p className="footer-text"><b>La Bella Italia Lieben und Essen</b></p>
                </div>
            </div>
        </div>
    );
};

export default Header;
