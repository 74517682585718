// Inject a script into the DOM
export const injectScript = (src, id) => {
    if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id; // Assign an ID for easy reference
        script.async = false;
        document.body.appendChild(script); // Append it to the body or head
    }
};

// Remove a script from the DOM
export const removeScript = (id) => {
    const script = document.getElementById(id);
    if (script) {
        script.remove();
    }
};


